import AXIOS from '../Config/AxiosConfig';
import { GENERAL } from '../Config/env';

const baseURL = '/v2/admin-panel/diocese';
const Parish = {
  getParishes(parishId, token, currentPage, orderBy, order) {
    const queryParams = new URLSearchParams({
      page: currentPage || 1,
      limit: GENERAL.paginator_limit,
      order: order || 'asc',
      order_by: orderBy || undefined,
    });

    return AXIOS.get(`${baseURL}/${parishId}/parish?${queryParams.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Diocesan-Application': 'admin',
      },
    });
  },
};
export default Parish;

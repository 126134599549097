import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, NavLink, Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import { Scrollbars } from 'react-custom-scrollbars';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { enableRightDrawer as enableRightDrawerShort } from '../../Actions/rightDrawerShortActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import RightDrawer from '../RightDrawer';
import RightDrawerShort from '../RightDrawerShort';
import changeTab from '../../Actions/mainTabsActions';
import SnackBar from '../Snackbar';
import { checkSsoLogin } from '../../Actions/authHttpActions';
import Loading from '../Loading';
import './index.sass';

const TabContainer = props => <Typography component="div">{props.children}</Typography>;

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  label: {
    fontSize: '20px !important',
  },
  wrapper: {},
  tab: {
    color: theme.palette.background.paper,
  },
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
    height: '4px',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',

    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabSelected: { color: '#9a66bf' },
  pageContainer: {
    height: '100vh',
  },
  '@media (max-width: 768px)': {
    pageContainer: {
      height: 'calc(100vh - 60px)',
    },
  },
});
class SimpleTabs extends React.Component {
  // static contextTypes = {
  //   router: PropTypes.instanceOf(Object).isRequired,
  // };

  componentWillMount() {
    this.props.checkSsoLogin(localStorage.getItem('sso_token'));
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    let tab;

    switch (pathname) {
      case '/':
      case '/main':
      case '/organizations':
        tab = 0;
        break;
      case '/short-codes':
        tab = 1;
        break;
      case '/statistic':
        tab = 2;
        break;
      case '/reports':
        tab = 3;
        break;
      default:
        tab = null;
    }

    this.props.changeTab(tab);
  }

  handleChange = (event, value) => {
    this.props.enableRightDrawer({ payload: { close: false, enable: false } });
    this.props.enableRightDrawerShort({ payload: { enable: false } });
    this.props.changeTab(value);
  };

  render() {
    const { classes, content, value } = this.props;
    const pageContainerHeight = window.innerWidth <= 768 ? '100vh - 85px' : '100vh - 110px';
    return (
      <div className={classes.root}>
        <div className="page-app-bar" id="main-menu-app">
          <AppBar
            position="static"
            style={{
              backgroundColor: '#F3F3F3',
            }}
          >
            <Tabs
              className="simple-tabs-menu"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              value={value}
              onChange={this.handleChange}
            >
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label="Organizations"
                icon={<i className="org-icons" />}
                component={NavLink}
                to="/organizations"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label="Short Codes"
                icon={<i className="none-yet" />}
                component={NavLink}
                to="/short-codes"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label="Statistic"
                icon={<i className="none-yet" />}
                component={NavLink}
                to="/statistic"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label="Reports"
                icon={<i className="none-yet" />}
                component={NavLink}
                to="/reports"
              />
            </Tabs>
            <div
              className="search-top-container"
              role="button"
              onKeyPress={event => event.preventDefault()}
              onClick={() => this.props.changeTab(false)}
              tabIndex="-1"
            >
              <Link to="/super-admins">
                <i
                  className={
                    this.props.value === false
                      ? 'super-admin-icon-active'
                      : 'super-admin-icon-inactive'
                  }
                />
              </Link>
            </div>
          </AppBar>
        </div>
        <div className={`page-container ${classes.pageContainer}`}>
          <Scrollbars autoHeight autoHide autoHeightMin={0} autoHeightMax={pageContainerHeight}>
            <TabContainer>
              <SnackBar />
              <Loading />
              <RightDrawer />
              <RightDrawerShort />
              {content}
            </TabContainer>
            <div className="footer-eva-admin">
              <p>&copy; 2018 Evangelus. All rights reserved.</p>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enableRightDrawer: PropTypes.func.isRequired,
  enableRightDrawerShort: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
  checkSsoLogin: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

const mS = state => ({
  value: state.mainTabsReducer.value,
});

const mD = {
  enableRightDrawer,
  enableRightDrawerShort,
  changeTab,
  checkSsoLogin,
};

export default withRouter(
  withStyles(styles)(
    connect(
      mS,
      mD,
    )(SimpleTabs),
  ),
);

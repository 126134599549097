/* eslint-disable */
import React from 'react';
import MainMenu from '../../Components/MainMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import EvaButton from '../../Components/EvaButton';
import DatePicker from '../../Components/DatePicker';
import SimpleSelect from '../../Components/SimpleSelect';
import Organization from '../../Api/Organization';
import ReportAPI from '../../Api/Report';
import moment from 'moment/moment';
import download from 'downloadjs';
import './index.sass';

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        from: '', 
        to: '',
        orgId: 'all',
      },
      loading: false,
      organizations: [],
    };
  }

  componentDidMount() {
    this.fetchOrganizations();
  }

  fetchOrganizations = () => {
    Organization.getOrganizationsShort()
      .then(res => {
        const organizations = res.data.data.organizations.map(org => ({
          value: org.publicId,
          label: org.name,
        }));
        const allOrganizationsOption = { value: 'all', label: "All Organizations" };
        organizations.unshift(allOrganizationsOption);

        this.setState({ organizations });
      })
      .catch(() => {
        this.setState({ ...this.state, loading: false })
      });
  };

  handleOrgChange = (event) => {
    this.setState({
      filter: {
        ...this.state.filter,
        orgId: event.target.value
      }
    });
  };

  renderProgress = () => <CircularProgress className="DataTable-progress-52" style={{ color: '#9c27b0' }} thickness={7} />

  downloadSMSMessagesSentFromOrganizationsReport = () => {
    const { from, to, orgId } = this.state.filter;
    this.setState({ ...this.state, loading: true })
    ReportAPI.getSMSMessagesSentFromOrganizationsReport(from, to, orgId)
      .then(a => {
        download(new Blob([a.data]), `report - SMS Messages Sent From Organizations.csv`, 'text/csv')
        this.setState({ ...this.state, loading: false })
      })
      .catch(() => {
        this.setState({ ...this.state, loading: false })
      })
  };

  render() {
    return (
      <MainMenu
        value={0}
        content={<div className="report-root">
          <div className="report-form">
            <div className="form-wrapper">
              <SimpleSelect
                value={this.state.filter.orgId}
                handleChange={this.handleOrgChange}
                options={this.state.organizations}
                className="organization-select input-eva-border-bottom input-eva select-eva-border-bottom select"
              />
              <div className="datepickers-wrapper">
                <DatePicker
                  placeholder="Range From"
                  value={this.state.filter.from ? this.state.filter.from : null}
                  handleSelection={(e, inst) => {
                    const value = moment(inst.getVal());
                    this.setState({ ...this.state, filter: { ...this.state.filter, from: value._i } });
                  }}
                  max={new Date()}
                  controls={['calendar']}
                  dateFormat="yy-mm-dd"
                  timeFormat="h:ii A"
                  returnFormat="iso8601"
                  iconLeft="none"
                  iconRight="none"
                />
                <DatePicker
                  placeholder="Range To"
                  value={this.state.filter.to ? this.state.filter.to : null}
                  handleSelection={(e, inst) => {
                    const value = moment(inst.getVal());
                    this.setState({ ...this.state, filter: { ...this.state.filter, to: value._i } });
                  }}
                  min={this.state.filter.from}
                  max={new Date()}
                  controls={['calendar']}
                  dateFormat="yy-mm-dd"
                  timeFormat="h:ii A"
                  returnFormat="iso8601"
                  iconLeft="none"
                  iconRight="none"
                />
              </div>
            </div>

            {this.state.loading && (<div className="progress-wrapper"> {this.renderProgress()}</div>)}

            <div className="buttons-wrapper">
              <EvaButton onClick={this.downloadSMSMessagesSentFromOrganizationsReport} className="create-button">
                Download SMS messages sent from Organizations 
              </EvaButton>
            </div>
          </div>
        </div>}
      />
    );
  }
}

export default Report;

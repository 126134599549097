import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const baseURL = '/admin-panel';
const StatisticAPI = {
  getStatistic(from, to) {
    return AXIOS.get(
      `${baseURL}/bulletin-statistic${from || to ? `?from=${from}&to=${to}` : ''}`,
      {
        headers: headers(),
      },
    );
  },

  getFailedBulletinReport(from, to) {
    return AXIOS.get(
      `${baseURL}/failed-bulletin-report${from || to ? `?from=${from}&to=${to}` : ''}`,
      {
        headers: headers(),
      },
    );
  },

  getFeaturedTemplateReport(from, to) {
    return AXIOS.get(
      `${baseURL}/featured-template-report${from || to ? `?from=${from}&to=${to}` : ''}`,
      {
        headers: headers(),
      },
    );
  },

  getStatisticFile(from, to, type) {
    return AXIOS.get(
      `${baseURL}/bulletin-organization-statistic?type=${type}${(from || to) ? `&from=${from}&to=${to}` : ''}`,
      {
        headers: headers(),
      },
    );
  },
};
export default StatisticAPI;

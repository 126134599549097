import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from '@material-ui/core';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import EditPictureOrgPropType from '../../PropTypes/EditPictureOrgPropType';
import { updateUpdPicture } from '../../Actions/organizationActions';
import './index.sass';

const EditPictureOrg = (props) => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(props.pictureData.img || props.mainPictureUrl);
  const [scaleValue, setScaleValue] = useState(50);
  const editorRef = useRef(null);

  const handleDrop = (files) => setFile(files[0]);

  const handleScaleChange = (_, value) => setScaleValue(value);


  const handleCancel = () => {
    setFile(null);

    if (props.changeDisplay) {
      props.changeDisplay({ displayPicture: false });
    }

    props.onClose();
  };

  const submit = (url) => {
    props.updateUpdPicture({
      img: url,
    });
  }

  const handleSubmit = () => {
    if (!imageUrl) {
      submit(null);
      return;
    }

    try {
      if (editorRef && editorRef.current) {
        const canvas = editorRef.current.getImageScaledToCanvas();
        const data = canvas.toDataURL('image/png');

        submit(data);
        props.onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleImageRemove = () => {
    setImageUrl(null);
    setFile(null);
  };

  const source = file || imageUrl;

  return (
    <div className="edit-picture">
      <Dropzone
        className="crop-zone"
        accept="image/jpeg, image/png"
        onDrop={handleDrop}
        disabled={!!source}
        multiple={false}
      >
        {!source && (
          <div className="drop-info-container">
            <div className="drop-info">
              Drop image file here or click to upload.
            </div>

            <div className="crop-image">
              <label
                className="upload-fake-button"
                htmlFor="input-picture"
              >
                Upload an image
              </label>
            </div>
          </div>
        )}

        {source && (
          <>
            <AvatarEditor
              image={source}
              width={300}
              ref={editorRef}
              scale={(scaleValue * 2) / 100}
              crossOrigin="anonymous"
              height={300}
              borderRadius={300}
            />
            <Slider value={scaleValue} aria-labelledby="label" onChange={handleScaleChange} />
          </>
        )}
      </Dropzone>

      {source && (
        <>
          <div className="actions-container">
            <button className="button-filled" onClick={handleSubmit}>
              Crop and accept
            </button>
          </div>
          <div className="actions-container">
            <button className="remove-button" onClick={handleImageRemove}>
              Remove
            </button>
          </div>
        </>
      )}
      <button onClick={handleCancel} className="cancel-button">
        Cancel
      </button>
    </div>
  )
}

EditPictureOrg.propTypes = EditPictureOrgPropType;

const mS = state => ({
  mainPictureUrl: state.organizationReducer.mainPictureUrl,
  pictureData: state.organizationReducer.pictureData,
});

const mD = {
  updateUpdPicture,
  enableRightDrawer,
};

export default connect(
  mS,
  mD,
)(EditPictureOrg);

import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const baseURL = '/admin-panel/report';

const ReportAPI = {
  getSMSMessagesSentFromOrganizationsReport(from, to, orgId) {
    let url = `${baseURL}/sms-organizations-messages`;
    const queryParams = [];
    if (orgId && orgId !== 'all') {
      queryParams.push(`orgId=${orgId}`);
    }
    if (from !== '' && to !== '') {
      queryParams.push(`from=${from}`);
      queryParams.push(`to=${to}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return AXIOS.get(url, { headers: headers() });
  }
};

export default ReportAPI;
